import React from 'react';
import { Modal } from '@material-ui/core';
import './../../styles/inbox/simplegridModal.css';

/**
 * SimpleGridModal component renders a modal with a simple grid table.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.toggleModal - Function to toggle the modal visibility.
 * @param {Object} props.simpleGridData - Data to be displayed in the grid.
 * @param {Object} props.simpleGridData.data - The main data object containing header and rows.
 * @param {Object} props.simpleGridData.data.header - The header object for the grid columns.
 * @param {string} props.fieldLabel - The label to be displayed in the modal header.
 *
 * @returns {JSX.Element} The rendered SimpleGridModal component.
 */
const SimpleGridModal = ({ toggleModal, simpleGridData, fieldLabel }: any) => {
  /**
   * Renders the header for the simple grid table.
   *
   * This function returns a JSX element that represents the header row of the simple grid table.
   * The header row contains a fixed first column with a "#" symbol and dynamically generated columns
   * based on the keys of the `simpleGridData.data.header` object.
   *
   * @returns {JSX.Element} The JSX element representing the header row of the simple grid table.
   */
  const renderHeader = () => {
    return (
      <div className="simple-grid-table-row simple-grid-table-header">
        <div className="simple-grid-table-cell simple-grid-table-header-cell simple-grid-table-header-cell-first-column">
          #
        </div>
        {simpleGridData &&
          simpleGridData.data &&
          simpleGridData.data.header &&
          Object.keys(simpleGridData.data.header).map((key) => (
            <div
              className="simple-grid-table-cell simple-grid-table-header-cell simple-grid-table-header-cell-field-column"
              key={key}
            >
              {simpleGridData.data.header[key]}
            </div>
          ))}
      </div>
    );
  };

  /**
   * Renders a table row with data.
   *
   * @param {number} index - The index of the row.
   * @param {string} key - The key for the first column.
   * @param {unknown} values - The values for the remaining columns.
   * @returns {JSX.Element} The rendered table row.
   */
  const renderTableData = (index: number, key: string, values: unknown) => {
    return (
      <div
        className="simple-grid-table-row"
        key={index}
        style={{
          backgroundColor: index % 2 === 0 ? '#E1FBFF' : 'white',
        }}
      >
        <div className="simple-grid-table-cell simple-grid-table-cell-first-column">
          {key}
        </div>
        {Object.keys(values as object).map((field) => (
          <div className="simple-grid-table-cell" key={field}>
            <input
              type="text"
              className="simple-grid-table-cell-input"
              defaultValue={(values as any)[field]}
              onChange={() => {}}
              disabled={true}
            />
          </div>
        ))}
      </div>
    );
  };

  /**
   * Renders the footer for the simple grid modal.
   *
   * @returns {JSX.Element} The JSX element representing the footer.
   */
  const renderFooter = () => {
    return (
      <div className="simple-grid-modal-footer">
        <div className="simple-grid-modal-footer-summary-wrapper">
          <p className="simple-grid-modal-footer-summary-label">
            {simpleGridData.label ? simpleGridData.label : ''}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Modal open={true} className="simple-grid-modal-wrapper">
      <div className="simple-grid-modal-container">
        <div className="simple-grid-modal-header">
          <p>{fieldLabel}</p>
          <i
            className="far fa-times-circle close-button"
            onClick={toggleModal}
          />
        </div>
        <div className="simple-grid-modal-body">
          <div className="simple-grid-table-wrapper">
            {renderHeader()}
            {simpleGridData &&
              simpleGridData.data &&
              Object.entries(simpleGridData.data)
                .filter(
                  ([key]) =>
                    key !== 'header' &&
                    key !== 'type' &&
                    key !== 'style' &&
                    key !== `${String.fromCharCode(931)}`
                )
                .map(([key, values], index) =>
                  renderTableData(index, key, values)
                )}
          </div>
        </div>
        {renderFooter()}
      </div>
    </Modal>
  );
};

export default SimpleGridModal;
