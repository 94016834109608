import moment from 'moment';
import * as _ from 'lodash';
import { compileExpression } from 'filtrex';
import flatten from 'flat';
import ST from 'stjs';

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = (phone: string) => {
  // eslint-disable-next-line
  const re = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;
  const length = phone.includes('+') ? phone.slice(1).length : phone.length;
  const result = re.test(phone) && length === 12;
  return result;
};

export const validatePhoneForForm = (phone: string) => {
  const re = /^(\+\d{1,3})?\d{12}$/; // Match numbers with optional country code.
  return re.test(phone);
};

export const capitalizeFirstLetter = (data: string) => {
  return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
};

export const decodeAccessToken = (token: any) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const checkPreCondition = (data: any, expr: any) => {
  const flatObj = flatten(data);
  const myFilters = compileExpression(expr);
  let isEnable = false;
  isEnable = myFilters(flatObj);
  return isEnable;
};

/*
 * validation for null/empty value;
 */
export const isNull = (value: any) => {
  switch (typeof value) {
    case 'object':
      if (value === null || Object.keys(value).length === 0) {
        return true;
      }
      if (Object.keys(value).length && !Array.isArray(value)) {
        return false;
      }
      if (Array.isArray(value) && value.length) {
        return false;
      }
      break;
    case 'string':
    case 'number':
      if (value.toString().length) {
        return false;
      } else {
        return true;
      }
    case 'undefined':
      return true;
    default:
      return false;
  }
  return true;
};

/*
 * Used to format date;
 */
export const dateFilterFormater = (label: any) => {
  const formatedValue: any = { from: null, to: null, label: label };
  switch (label.toLowerCase()) {
    case 'today':
      formatedValue.from = moment().startOf('day').toISOString();
      formatedValue.to = moment().endOf('day').toISOString();
      break;
    case 'current month':
      formatedValue.from = moment().startOf('month').toISOString();
      formatedValue.to = moment().endOf('month').toISOString();
      break;
    case 'last month':
      formatedValue.from = moment()
        .month(moment().month() - 1)
        .startOf('month')
        .toISOString();
      formatedValue.to = moment()
        .month(moment().month() - 1)
        .endOf('month')
        .toISOString();
      break;
    case 'last 30 days':
      formatedValue.from = moment()
        .subtract(30, 'day')
        .startOf('day')
        .toISOString();
      formatedValue.to = moment().subtract(1, 'day').endOf('day').toISOString();
      break;
    case 'last 60 days':
      formatedValue.from = moment()
        .subtract(60, 'day')
        .startOf('day')
        .toISOString();
      formatedValue.to = moment().subtract(1, 'day').endOf('day').toISOString();
      break;
    case 'last 90 days':
      formatedValue.from = moment()
        .subtract(90, 'day')
        .startOf('day')
        .toISOString();
      formatedValue.to = moment().subtract(1, 'day').endOf('day').toISOString();
      break;
    case 'last 3 months':
      formatedValue.from = moment()
        .month(moment().month() - 3)
        .startOf('month')
        .toISOString();
      formatedValue.to = moment()
        .month(moment().month() - 1)
        .endOf('month')
        .toISOString();
      break;
    case 'last 6 months':
      formatedValue.from = moment()
        .month(moment().month() - 6)
        .startOf('month')
        .toISOString();
      formatedValue.to = moment()
        .month(moment().month() - 1)
        .endOf('month')
        .toISOString();
      break;
    case 'yesterday':
      formatedValue.from = moment()
        .subtract(1, 'day')
        .startOf('day')
        .toISOString();
      formatedValue.to = moment().subtract(1, 'day').endOf('day').toISOString();
      break;
    case 'overdue':
      formatedValue.from = moment()
        .add(-6, 'month')
        .startOf('day')
        .toISOString();
      formatedValue.to = moment().add(1, 'month').endOf('day').toISOString();
      break;
    default:
      formatedValue.from = moment().startOf('month').toISOString();
      formatedValue.to = moment().endOf('month').toISOString();
      break;
  }
  return formatedValue;
};

/**
 * Generate inbox query
 */
export const getInboxDefaultQuery = (workflow: any) => {
  const defaultFilter = [];
  if (sessionStorage.getItem('accessToken')) {
    const designationId = decodeAccessToken(
      sessionStorage.getItem('accessToken')
    );
    if (workflow && designationId) {
      const templateString = workflow['template'];

      const dataModificationAccess = workflow['data_modification_access']
        ? workflow['data_modification_access']
        : 'ASSIGNEE_ONLY';
      let collaboratorFieldName = '';
      if (templateString) {
        const template = JSON.parse(templateString);
        const collaboratorField = _.find(template['fields'], {
          type: 'collaborator',
        });
        if (typeof collaboratorField !== 'undefined') {
          collaboratorFieldName = collaboratorField['name'];
        }
      }

      switch (dataModificationAccess.toUpperCase()) {
        case 'COLLABORATOR_ONLY':
          if (collaboratorFieldName) {
            defaultFilter.push({
              name: collaboratorFieldName,
              value: [designationId.did],
              label: 'Me',
              type: 'user',
            });
          }
          break;
        case 'EITHER_ASSIGNEE_OR_COLLABORATOR':
          if (collaboratorFieldName) {
            defaultFilter.push({
              name: collaboratorFieldName,
              value: [designationId.did],
              label: 'Me',
              type: 'user',
            });
          }
          defaultFilter.push({
            name: '_mo_assignee.desig_id',
            value: [designationId.did],
            label: 'Me',
            type: 'user',
          });

          break;
        case 'ASSIGNEE_ONLY':
        default:
          defaultFilter.push({
            name: '_mo_assignee.desig_id',
            value: [designationId.did],
            label: 'Me',
            type: 'user',
          });
          break;
      }
    }
  }
  return defaultFilter;
};

/*
 * Get unique values in userfilter and defaultfilter
 */
export const mergeSelectedAndDefaultFilter = (
  userFilter: any,
  defaultFilter: any
) => {
  let queryFilter;
  const defultFilterNotInUserFilter = _.filter(defaultFilter, (field: any) => {
    const item = _.findIndex(userFilter, { type: field['type'] });
    if (item === -1) {
      return field;
    }
  });
  if (defultFilterNotInUserFilter.length) {
    // combined the user selected filter and the default filter not in user selected filter
    queryFilter = [...userFilter, ...defultFilterNotInUserFilter];
  } else {
    queryFilter = [...userFilter];
  }
  return queryFilter;
};

/**
 * Cobmine userfilter and defaultfilter
 */
export const getSingleArrayFromAllFilter = (filter: any) => {
  let queryFilter = [];
  if (filter !== null) {
    if (filter['userFilter'] !== null && filter['defaultFilter'] !== null) {
      queryFilter = mergeSelectedAndDefaultFilter(
        filter.userFilter,
        filter.defaultFilter
      );
    } else if (filter['userFilter'] !== null) {
      queryFilter = filter['userFilter'];
    } else if (filter['defaultFilter'] !== null) {
      queryFilter = filter['defaultFilter'];
    }
  }
  return queryFilter;
};

/*
 * update date before sending request to backend;
 */
export const autoUpdateFilterDate = (selectedfilter: any) => {
  if (selectedfilter !== null && Array.isArray(selectedfilter)) {
    selectedfilter = selectedfilter.map((obj) => {
      if (['date'].includes(obj.type)) {
        obj['value'] = dateFilterFormater(obj['label']);
      }
      return obj;
    });
  }
  return selectedfilter;
};

export const constructDateQueryString = (values: any, name: any) => {
  let query = '';
  if (values !== null && Array.isArray(values)) {
    values.forEach((valueItem) => {
      const keys = Object.keys(valueItem);
      let queryString = '';
      if (keys.length) {
        keys.map((key) => {
          switch (key) {
            case 'from':
              queryString = queryString.length
                ? `${queryString}&${name}>${valueItem[key]}`
                : `${name}>${valueItem[key]}`;
              break;
            case 'to':
              queryString = queryString.length
                ? `${queryString}&${name}<${valueItem[key]}`
                : `${name}<${valueItem[key]}`;
              break;
            default:
              break;
          }
          return null;
        });
      }
      if (query.length) {
        query = `${query}&${queryString}`;
      } else {
        query = queryString;
      }
    });
  }
  return query;
};

/**
 * Generate query key value pair
 */
export const queryStringBuilder = (selectedfilter: any) => {
  let queryString = '';
  if (selectedfilter && Object.keys(selectedfilter).length) {
    const queryFilter = getSingleArrayFromAllFilter(selectedfilter);
    if (queryFilter !== null && Array.isArray(queryFilter)) {
      queryFilter.forEach((filterItem) => {
        const name = filterItem['name'];
        let values = filterItem['value'];
        const type = filterItem['type'];
        let query = '';
        switch (type) {
          case 'date':
            values = autoUpdateFilterDate(values);
            query = constructDateQueryString(values, name);
            break;
          case 'user':
          case 'string':
          case 'picker':
          case 'status_picker':
          case 'number':
            query = `${name}=${values}`;
            break;
          default:
            break;
        }
        if (queryString.length) {
          queryString = `${queryString}&${query}`;
        } else {
          queryString = query;
        }
      });
    }
  }
  return queryString;
};

export const isImageExists = (fields: any, fieldName: any) => {
  const field = _.find(fields, { name: fieldName, type: 'image' });
  const signatureField = _.find(fields, { name: fieldName, type: 'signature' });
  if (field || signatureField) return true;
  return false;
};

export const getInboxViewConfig = (allInboxConfigs: any, viewName = '') => {
  if (isNull(allInboxConfigs)) {
    return null;
  } else {
    if (isNull(viewName)) {
      const config = _.find(allInboxConfigs, { is_default: true });
      return !isNull(config) && !isNull(config['view_config'])
        ? JSON.parse(config['view_config'])
        : null;
    } else {
      const config = _.find(allInboxConfigs, { name: viewName });
      return !isNull(config) && !isNull(config['view_config'])
        ? JSON.parse(config['view_config'])
        : null;
    }
  }
};

export const getFormatedInboxData = (
  result: any,
  allInboxConfigs: any,
  stageName = ''
) => {
  const config = getInboxViewConfig(allInboxConfigs, stageName);
  const displayTemplate = !isNull(config) ? config : null;
  if (!isNull(displayTemplate) && !isNull(result)) {
    const details = {
      value_type: 'details',
      value: '{{this}}',
      formatter: '',
      prepend: '',
      parse_type: '',
    };
    const displayTemplateXtended = {
      ...displayTemplate,
      details,
    };
    const data = ST.select({ data: result })
      .transformWith({
        '{{#each data}}': displayTemplateXtended,
      })
      .root();
    const d = data && data != null ? data : {};
    return d;
  }
  return null;
};

export const refactorUserDirectoryDesignation = (data: any) => {
  return data.map((item: any) => {
    const desigName = item?.desig_name;
    const desigId = item?.desig_id;
    const userAlias = item?.user_alias;
    const userId = item?.user_id;

    return {
      desig_name: desigName,
      desig_id: desigId,
      user_alias: userAlias,
      user_id: userId,
    };
  });
};

export const unsupportedMessage = (fields: any) => {
  const types = [
    'barcode',
    'collaborator',
    'assignee',
    'product-catalog-sale',
    'sub-form',
    'switch',
  ];
  const unsupportedFields: string[] = [];

  !isNull(fields) &&
    fields.forEach((item: any) => {
      if (types.includes(item.type) && item.required) {
        unsupportedFields.push(item.label);
      }
    });

  return !isNull(unsupportedFields)
    ? `Error : This form contains unsupported fields (${unsupportedFields.join(
        ', '
      )}). You will not be able to submit the details.`
    : null;
};

export const getDefaultValue = (field: any) => {
  switch (field.type) {
    case 'text':
    case 'phone':
    case 'image':
      return field.defaultValue || '';

    case 'status_picker':
    case 'picker':
      if (field.options.indexOf(field.defaultValue) !== -1) {
        return field.defaultValue;
      }
      return field.options[0];

    default:
      return null;
  }
};

export const validateMobileNumber = (mobNumber: any) => {
  const re = /^(\+([0-9]{2}))?(([6-9]{1})+([0-9]{9}))$/;
  return re.test(mobNumber);
};

export const validateCustomForm = (field: any) => {
  let error = false;
  let errorMsg = '';

  if (field.required) {
    switch (field.type) {
      case 'text':
      case 'image':
        if (isNull(field.value)) {
          error = true;
          errorMsg = `${field.label} is required`;
        }
        break;

      case 'phone':
        if (isNull(field.value)) {
          error = true;
          errorMsg = `${field.label} is required`;
        } else if (
          !validateMobileNumber(field.value) ||
          ![10, 12].includes(field.value.length)
        ) {
          error = true;
          errorMsg = `${field.label} should be valid mobile number`;
        }
        break;

      case 'picker':
      case 'status_picker':
        if (isNull(field.value) || field.value === '-Select-') {
          error = true;
          errorMsg = `${field.label} is required`;
        }
        break;
      default:
    }
  }
  return { error, errorMsg };
};

export const removeBase64Data = (data: any) => {
  const objKeys = Object.keys(data);
  const updatedData: any = {};
  objKeys.forEach((item) => {
    if (
      data[item] &&
      typeof data[item] === 'object' &&
      Array.isArray(data[item])
    ) {
      const obj = data[item].map((item1: any) => {
        delete item1['base64Data'];
        return item1;
      });
      updatedData[item] = obj;
    } else {
      updatedData[item] = data[item];
    }
  });

  return updatedData;
};

export const getUserDesigOption = (value: string) => {
  switch (value) {
    case '_MO_USER_DESIGNATION':
      return 'designation_name';
    case '_MO_USER_DESIGNATION_DEPARTMENT':
      return 'department';
    case '_MO_USER_DESIGNATION_ROLE':
      return 'role';
    case '_MO_USER_DESIGNATION_WORK_LOCATION':
      return 'work_location';
    case '_MO_USER_DESIGNATION_TAGS':
      return 'tags';
    case '_MO_USER_DESIGNATION_STATE':
      return 'state_name';
    case '_MO_USER_DESIGNATION_DIVISION':
      return 'division';
    case '_MO_USER_DESIGNATION_REGION':
      return 'region';
    case '_MO_USER_DESIGNATION_BRANCH':
      return 'branch';
    case '_MO_USER_FIRST_NAME':
      return 'first_name';
    case '_MO_USER_DISPLAY_NAME':
      return 'user_alias';
    case '_MO_USER_USER_NAME':
      return 'user_name';
    case '_MO_USER_MOBILE':
      return 'mobile';
    case '_MO_USER_EMAIL':
      return 'email';
    case '_MO_USER_EXTERNAL_ID':
      return 'external_id';
    case '_MO_USER_EMPLOYEE_ID':
      return 'employee_id';
    default:
      return null;
  }
};

export const getPickerDesigOptions = (field: any, allDesigDetails: any) => {
  if (
    !isNull(allDesigDetails) &&
    !isNull(field['additional_config']) &&
    !isNull(field['additional_config']['ref_user_field'])
  ) {
    const refField =
      getUserDesigOption(field['additional_config']['ref_user_field']) || '';
    const data: any = !isNull(refField)
      ? allDesigDetails
          .map((item: any) => item[refField])
          .filter((item: any) => item)
      : [];
    const updatedData: any = !isNull(data) ? new Set(data) : [];
    return !isNull(data) ? [...updatedData] : [];
  }
  return field['options'] ? field['options'] : [];
};

export const getPickerDefaultValue = (
  field: any,
  allDesigDetails: any,
  logginedDesigId: any
) => {
  if (
    logginedDesigId &&
    !isNull(allDesigDetails) &&
    !isNull(field['additional_config']) &&
    !isNull(field['additional_config']['ref_user_field'])
  ) {
    const refField =
      getUserDesigOption(field['additional_config']['ref_user_field']) || '';
    const userDesig = _.find(allDesigDetails, {
      _id: logginedDesigId,
    });
    const userDesigRefField =
      !isNull(userDesig) && !isNull(userDesig[refField]) && !isNull(refField)
        ? _.find(field['options'], (item: any) => item === userDesig[refField])
        : null;
    return !isNull(userDesigRefField) ? userDesigRefField : '';
  }
  return field['defaultValue'] ? field['defaultValue'] : '';
};

export const validateMobNumber = (mobNumber: string) => {
  const re = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;
  return re.test(mobNumber);
};

export const getUserDefaultValue = (
  field: any,
  profile: any,
  allDesigDetails: any,
  logginedDesigId: any
) => {
  if (
    !isNull(field['additional_config']) &&
    !isNull(field['additional_config']['ref_user_field'])
  ) {
    const refField =
      getUserDesigOption(field['additional_config']['ref_user_field']) || '';
    const desigObj = _.find(allDesigDetails, {
      _id: logginedDesigId,
    });
    const valueObj = [
      'first_name',
      'user_alias',
      'user_name',
      'mobile',
      'email',
      'employee_id',
      'external_id',
    ].includes(refField)
      ? profile
      : desigObj;
    const value =
      !isNull(valueObj) && !isNull(valueObj[refField])
        ? valueObj[refField]
        : null;
    return value;
  }
  return null;
};

export const isDesktop = () => {
  let isDesktop = false;
  if (window.innerWidth >= 960) {
    isDesktop = true;
  }

  return isDesktop;
};

export const getUserGroup = (userDesignation: any) => {
  if (
    userDesignation &&
    userDesignation.groups &&
    userDesignation.groups.length > 0
  ) {
    return userDesignation.groups.map((item: any) => item.value);
  }
  return [];
};

export const checkUserViewPermission = (
  stageName: string,
  stageViewGroups: any,
  userGroups: any
) => {
  if (!isNull(userGroups) && !isNull(stageViewGroups) && !isNull(stageName)) {
    switch (stageName) {
      case '_mo_activity':
      case '_mo_interaction':
        return true;

      default:
        const stagePermission = userGroups.some((id: any) =>
          stageViewGroups.includes(id)
        );
        return stagePermission;
    }
  } else {
    return false;
  }
};

export const getAllVisibleTab = (tabs: any, userGroups: any) => {
  let visibleTabs: any = [];
  if (!isNull(tabs) && !isNull(userGroups)) {
    _.forEach(tabs, (tab: any) => {
      const stageViewGroups =
        !isNull(tab) &&
        !isNull(tab['permissions']) &&
        !isNull(tab['permissions']['view'])
          ? tab['permissions']['view']
          : [];
      const stageUpdatedGroups =
        !isNull(tab) &&
        !isNull(tab['permissions']) &&
        !isNull(tab['permissions']['update'])
          ? tab['permissions']['update']
          : [];
      if (
        checkUserViewPermission(
          tab['name'],
          [...stageViewGroups, ...stageUpdatedGroups],
          userGroups
        )
      ) {
        visibleTabs.push(tab);
      }
    });
  }
  return visibleTabs;
};

export const isAssignedToMe = (me: any, assignee: any) => {
  if (!isNull(me) && !isNull(assignee)) {
    let { designation_id } = me;
    let { desig_id } = assignee;
    if (designation_id === desig_id) {
      return true;
    }
  }
  return false;
};

export const taskFilterQuery = (
  pageSize = 200,
  pageNumber: any,
  op = 'ALL',
  includeOpentasks: any,
  status = '',
  query = ''
) => {
  let filterQuery = `page_size=${pageSize}&page_number=${pageNumber}&op=${op}&include_opentasks=${includeOpentasks}`;
  if (status !== '') {
    filterQuery = `${filterQuery}&status=${status}`;
  }
  if (query !== '') {
    filterQuery = `${filterQuery}&${query}`;
  }
  return filterQuery;
};

export const isCreateStageOpenForEdit = (tab: any, userGroups: any) => {
  const stageName = '_mo_create';
  const stageUpdateGroups =
    !isNull(tab) &&
    !isNull(tab['permissions']) &&
    !isNull(tab['permissions']['update'])
      ? tab['permissions']['update']
      : null;

  const enabled = checkUserViewPermission(
    stageName,
    userGroups,
    stageUpdateGroups
  );
  return enabled;
};
